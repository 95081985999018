<template>
  <div>

  </div>
</template>
<script>
export default {
  components: {},
  props: {
    step: Number,
    steps: Number
  },
  data () {
    return {
      isEditing: true
    }
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.preventNav)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.preventNav)
  },
  beforeRouteLeave (to, from, next) {
    if (this.step + 1 < this.steps) {
      if (!window.confirm('Leave without saving?')) {
        return
      }
    }
    next()
  },
  methods: {
    preventNav (event) {
      if (this.step + 1 === this.steps) return
      event.preventDefault()
      event.returnValue = ''
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang='sass' scoped>
</style>
